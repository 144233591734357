<template>
  <v-main>
     <v-container fluid>
        <v-row no-gutters>
           <v-col cols="12">
              <v-card>
                 <v-container fluid>
                    <v-card-title v-if="course_action=='add'"> {{ 'Add Course Versions' | translate }}</v-card-title>
                    <v-card-title v-else-if="course_action=='edit'"> {{ 'Edit Course Versions (Draft)' | translate }}</v-card-title>
                    <v-card-title v-if="course_action=='draft'"> {{ 'Add Course Versions (Copy)' | translate }}</v-card-title>
                    <v-card-text style="position: relative">
                       <v-form id="form" ref="form">

                          <v-row>
                             <v-col cols="12" sm="12">
                                <v-row v-if="errors">
                                   <v-col cols="12" sm="12" md="12">
                                      <v-alert
                                         dense
                                         v-for="(v, k) in errors"
                                         :key="k"
                                         outlined
                                         type="error"
                                         >{{ k }} {{ v }}</v-alert>
                                   </v-col>
                                </v-row>
                                <v-row>
                                   <v-col cols="12" sm="4">
                                      <v-text-field
                                         name="course_name"
                                         outlined
                                         v-model="course_name"
                                         :rules="nameRules"
                                         :label="'Course name' | translate"
                                         required
                                         >
                                         <template #message="{ message }">
                                            {{ $t(message) }}
                                         </template>
                                      </v-text-field>
                                   </v-col>
                                   <v-col cols="12" sm="4">
                                      <v-file-input
                                         @change="onThumbnailSelected"
                                         id="thumbnail"
                                         accept="image/*"
                                         outlined
                                         chips
                                         :label="'Course image' | translate"
                                         ></v-file-input>
                                   </v-col>
                                   <v-col v-if="url" cols="12" sm="4">
                                      <v-img
                                         :src="url"
                                         aspect-ratio="1"
                                         class="grey lighten-2"
                                         max-width="250"
                                         max-height="150"
                                         ></v-img>
                                   </v-col>
                                </v-row>
                                <v-row>
                                   <v-col cols="12" sm="12">
                                      <v-textarea
                                         outlined
                                         name="description"
                                         v-model="description"
                                         :label="'Tag Line' | translate"
                                         :rules="descriptionRules"
                                         required
                                         >
                                         <template #message="{ message }">
                                            {{ $t(message) }}
                                         </template>
                                      </v-textarea>
                                   </v-col>
                                </v-row>
                                <v-row>
                                   <v-col cols="12" sm="12">
                                      <v-text-field
                                         name="abbreviation"
                                         outlined
                                         :label="'Abbreviation' | translate"
                                         v-model="abbreviation"
                                         :rules="abbreviationRules"
                                         required
                                         >
                                         <template #message="{ message }">
                                            {{ $t(message) }}
                                         </template>
                                      </v-text-field>
                                   </v-col>
                                </v-row>
                                <v-row>
                                   <v-col cols="12" sm="4">
                                      <v-text-field
                                         v-model="subscription_expiry_days"
                                         :label="'Subscription ends in (days)' | translate"
                                         prepend-icon="mdi-calendar"
                                         type="number"
                                         step="1"
                                         required
                                         :rules="dateRules"
                                         ></v-text-field>
                                   </v-col>
                                   <v-col cols="12" sm="4">
                                      <v-select
                                         v-model="course_domain"
                                         :items="domain_values"
                                         item-value="value"
                                         item-text="name"
                                         autocomplete="off"
                                         name="course_domain"
                                         outlined
                                         :label="'Course domain' | translate"
                                         :rules="domainRules"
                                         chips
                                         small-chips
                                         required
                                         ></v-select>
                                   </v-col>
                                   <v-col cols="12" sm="4">
                                      <v-select
                                         v-model="course_status"
                                         :items="course_status_values"
                                         item-value="value"
                                         item-text="name"
                                         autocomplete="off"
                                         name="course_status"
                                         outlined
                                         :label="'Course Status' | translate"
                                         :rules="coursestatusRules"
                                         chips
                                         small-chips
                                         required
                                         >
                                         <template #message="{ message }">
                                            {{ message | translate }}
                                         </template>
                                      </v-select>
                                   </v-col>
                                </v-row>
                                <v-row>
                                   <v-col cols="12" sm="12">
                                      <v-select
                                         v-model="author_name"
                                         :items="authors"
                                         :rules="authornameRules"                            
                                         item-value="author_id"
                                         item-text="author_name"
                                         :label="'Author' | translate"
                                         autocomplete="off"
                                         name="author_name"
                                         multiple
                                         outlined
                                         required
                                         >
                                         <template #message="{ message }">
                                            {{ $t(message) }}
                                         </template>
                                      </v-select>
                                   </v-col>
                                   <v-col cols="12" sm="12">
                                      <v-select
                                         v-model="selectedCourseSurveyId"
                                         :items="surveys"
                                         item-value="survey_id"
                                         item-text="survey_name"
                                         autocomplete="off"
                                         name="surveys"
                                         outlined
                                         chips
                                         small-chips
                                         :label="'Surveys' | translate"
                                         clearable
                                         hide-details
                                         @change="addSurvey(`${selectedCourseSurveyId}`)"
                                         ></v-select>
                                   </v-col>
                                </v-row>
                                <v-row>
                                   <v-col cols="12" sm="12">
                                      <v-text-field
                                         outlined
                                         name="certificate_description"
                                         v-model="certificate_description"
                                         :label="'Certificate description' | translate"
                                         :rules="certificatedescriptionRules"
                                         >
                                         <template #message="{ message }">
                                            {{ $t(message) }}
                                         </template>
                                      </v-text-field>
                                   </v-col>
                                </v-row>
                                <v-row>
                                   <v-col cols="12" sm="12">
                                      <h3 class="my-3">{{'Glossary' | translate}}</h3>
                                      <tiptap-vuetify name="glossary"
                                         :label="'Glossary' | translate"
                                         v-model="glossary" 
                                         :extensions="extensions"  />
                                   </v-col>
                                </v-row>
                                <v-row>
                                   <v-col cols="12" sm="12">
                                      <h3 class="my-3">{{'What Will You Learn?' | translate}}</h3>
                                      <tiptap-vuetify name="learn"
                                         v-model="learn"
                                         :extensions="extensions"  />
                                   </v-col>
                                </v-row>
                                <v-row>
                                   <v-col cols="12" sm="12">
                                      <h3 class="my-3">{{'Requirements' | translate}}</h3>
                                      <tiptap-vuetify name="requirement"
                                         v-model="requirement"
                                         :extensions="extensions"  />
                                   </v-col>
                                </v-row>
                                <v-row>
                                   <v-col cols="12" sm="12">
                                      <h3 class="my-3">{{'Long Description' | translate}}</h3>
                                      <tiptap-vuetify name="long_description"
                                         v-model="long_description"
                                         :extensions="extensions"  />
                                   </v-col>
                                </v-row>
                                <v-row class="mt-12">
                                   <v-col cols="12" sm="12">
                                      <v-tabs
                                         v-model="tabs"
                                         fixed-tabs
                                         >
                                         <v-tabs-slider></v-tabs-slider>
                                         <v-tab
                                            href="#passingrequirements"
                                            class="primary--text"
                                            >
                                            {{ 'Passing Requirements' | translate}}
                                         </v-tab>
                                         <v-tab
                                            href="#generaldocuments"
                                            class="primary--text"
                                            >
                                            {{ 'General documents' | translate}}
                                         </v-tab>
                                         <v-tab
                                            href="#certifications"
                                            class="primary--text"
                                            >
                                            {{ 'Certifications' | translate}}
                                         </v-tab>
                                      </v-tabs>
                                      <v-tabs-items v-model="tabs">
                                         <v-tab-item value="passingrequirements">
                                            <v-card flat>
                                               <v-card-title>Passing Requirements</v-card-title>
                                               <v-card-text style="position: relative">
                                                  <v-row>
                                                     <v-col cols="12" sm="12">
                                                        <v-row>
                                                           <v-col cols="12" sm="12">
                                                              <v-checkbox
                                                                 v-model="has_sort" 
                                                                 :label="`Has Sort?` | translate"
                                                                 @click="hasSortChanged()"
                                                                 hide-details
                                                                 ></v-checkbox>
                                                           </v-col>
                                                        </v-row>
                                                        <v-row>
                                                           <v-col cols="12" sm="12">
                                                              <v-chip-group
                                                                 active-class="primary--text"
                                                                 column
                                                                 >
                                                                 <v-chip
                                                                    v-for="modulesSelected in company_modules"
                                                                    :key="modulesSelected.module_id"
                                                                    >
                                                                    <v-avatar
                                                                       right
                                                                       class="primary mr-2 white--text"
                                                                       v-if="has_sort"
                                                                       >
                                                                       {{ modulesSelected.sort_sequence }}
                                                                    </v-avatar>
                                                                    {{ modulesSelected.title }}
                                                                 </v-chip>
                                                              </v-chip-group>
                                                           </v-col>
                                                        </v-row>
                                                        <v-row>
                                                           <v-col cols="12" sm="12">
                                                              <v-text-field
                                                                 outlined
                                                                 autocomplete="off"
                                                                 :label="'Search Modules' | translate"
                                                                 v-model="typedWord"
                                                                 @keyup="onClickSearch()"
                                                                 ></v-text-field>
                                                              <v-row>
                                                                 <v-col
                                                                    cols="12"
                                                                    sm="3"
                                                                    v-for="datas in searchData"
                                                                    :key="datas.module_id"
                                                                    >
                                                                    <v-card class="ma-2 mt-n1" height="100%">
                                                                       <v-row>
                                                                          <v-col cols="12">
                                                                             <v-img
                                                                                height="50"
                                                                                :src="storage_url + datas.thumbnail "
                                                                                ></v-img>
                                                                          </v-col>
                                                                       </v-row>
                                                                       <v-row>
                                                                          <v-col cols="12">
                                                                             <v-card-title style="position: relative">
                                                                                {{ datas.title }}
                                                                                <v-btn
                                                                                   absolute
                                                                                   dark
                                                                                   fab
                                                                                   top
                                                                                   right
                                                                                   @click="removeModule(datas)"
                                                                                   color="pink lighten-1"
                                                                                   v-if="checkModule(datas.module_id)"
                                                                                   >
                                                                                   <v-icon>mdi-minus</v-icon>
                                                                                </v-btn>
                                                                                <v-btn
                                                                                   absolute
                                                                                   dark
                                                                                   fab
                                                                                   top
                                                                                   right
                                                                                   @click="addModule(datas)"
                                                                                   color="green lighten-1"
                                                                                   v-else
                                                                                   >
                                                                                   <v-icon>mdi-plus</v-icon>
                                                                                </v-btn>
                                                                             </v-card-title>
                                                                          </v-col>
                                                                       </v-row>
                                                                       <v-row>
                                                                          <v-col cols="12">
                                                                             <v-card-text>
                                                                                <div>{{ datas.description | readMore(255, '...') | stripeHTML }}... </div>
                                                                             </v-card-text>
                                                                          </v-col>
                                                                       </v-row>
                                                                       <v-row>
                                                                          <v-col cols="12">
                                                                             <v-select
                                                                                v-model="selectedCourseModTestId[datas.module_id]"
                                                                                :items="testdata"
                                                                                item-value="test_id"
                                                                                item-text="test_name"
                                                                                autocomplete="off"
                                                                                name="testdata"
                                                                                outlined
                                                                                chips
                                                                                :disabled="checkIfSelected(datas.module_id)"
                                                                                small-chips
                                                                                :label="'Tests' | translate"
                                                                                clearable
                                                                                hide-details
                                                                                @change="addTest(datas.module_id,selectedCourseModTestId[datas.module_id])"
                                                                                ></v-select>
                                                                          </v-col>
                                                                       </v-row>
                                                                    </v-card>
                                                                 </v-col>
                                                              </v-row>
                                                           </v-col>
                                                        </v-row>
                                                        <v-row>
                                                           <v-col cols="12" sm="12">
                                                              <v-select
                                                                 v-model="selectedCourseExamId"
                                                                 :items="examdata"
                                                                 item-value="test_id"
                                                                 item-text="test_name"
                                                                 autocomplete="off"
                                                                 name="examdata"
                                                                 outlined
                                                                 chips
                                                                 small-chips
                                                                 :label="'Exams' | translate"
                                                                 clearable
                                                                 hide-details
                                                                 @change="addExam(`${selectedCourseExamId}`)"
                                                                 ></v-select>
                                                           </v-col>
                                                           <v-col cols="12" sm="12">
                                                              <v-select
                                                                 v-model="selectedCourseMandatoryExercises"
                                                                 :items="exercises"
                                                                 item-value="exercise_id"
                                                                 item-text="exercise_name"
                                                                 autocomplete="off"
                                                                 name="mandatoryexercisedata"
                                                                 outlined
                                                                 chips
                                                                 multiple
                                                                 small-chips
                                                                 :label="'Mandatory Exercises' | translate"
                                                                 hide-details
                                                                 @change="addMandatoryExercises(`${selectedCourseMandatoryExercises}`)"
                                                                 ></v-select>
                                                           </v-col>
                                                           <v-col cols="12" sm="12">
                                                              <v-select
                                                                 v-model="selectedCourseTrainingExercises"
                                                                 :items="exercises"
                                                                 item-value="exercise_id"
                                                                 item-text="exercise_name"
                                                                 autocomplete="off"
                                                                 name="triningexercisedata"
                                                                 outlined
                                                                 chips
                                                                 multiple
                                                                 small-chips
                                                                 :label="'Training Exercises' | translate"
                                                                 hide-details
                                                                 @change="addTrainingExercises(`${selectedCourseTrainingExercises}`)"
                                                                 ></v-select>
                                                           </v-col>
                                                        </v-row>
                                                        <v-row>
                                                           <v-col cols="4" sm="4">
                                                              <v-text-field
                                                                 outlined
                                                                 v-model="company_events"
                                                                 :label="'Calendar Event Key' | translate"
                                                                 autocomplete="off"
                                                                 name="company_events"
                                                                 :hint="'Calendar event name that is required for a user to attend. Make sure you have this added in the name of the calendar event in microsoft calendar eg ##ARBEITEVENT##' | translate"
                                                                 persistent-hint
                                                                 >
                                                                 <template #message="{ message }">
                                                                    {{ message | translate }}
                                                                 </template>
                                                              </v-text-field>
                                                           </v-col>
                                                           <v-col cols="6" sm="6">
                                                              <v-text-field
                                                                 outlined
                                                                 v-model="event_key_description"
                                                                 :label="'Event Key Description' | translate"
                                                                 autocomplete="off"
                                                                 name="event_key_description"
                                                                 :hint="'Event Key description for the user to prompt the user to join the event inorder to pass teh course.' | translate"
                                                                 persistent-hint
                                                                 >
                                                                 <template #message="{ message }">
                                                                    {{ message | translate }}
                                                                 </template>
                                                              </v-text-field>
                                                           </v-col>
                                                           <v-col cols="2" sm="2">
                                                              <v-btn class="mt-4" small @click="handleInput()">Add</v-btn>
                                                           </v-col>
                                                           <v-chip
                                                              v-for="(chip, chipindex) in chipList"
                                                              :key="chipindex"
                                                              class="ma-2"
                                                              @click="removeChip(chipindex)"
                                                              >
                                                              {{ chip.event_key }}
                                                              <v-icon small>mdi-close</v-icon>
                                                           </v-chip>
                                                        </v-row>
                                                     </v-col>
                                                  </v-row>
                                               </v-card-text>
                                            </v-card>
                                         </v-tab-item>
                                         <v-tab-item
                                            value="generaldocuments"
                                            >
                                            <v-card>
                                               <v-card-title>Add General documents</v-card-title>
                                               <v-card-text>
                                                  <v-container>
                                                     <v-form id="modelform" ref="modelform">
                                                        <v-row>
                                                           <v-col v-if="documenterrors" cols="12" sm="12" md="12">
                                                              <v-alert
                                                                 dense
                                                                 v-for="(v, k) in documenterrors"
                                                                 :key="k"
                                                                 outlined
                                                                 type="error"
                                                                 >{{ k }} {{ v }}</v-alert>
                                                           </v-col>
                                                        </v-row>
                                                        <v-row>
                                                           <v-col cols="12" sm="12">
                                                              <v-select
                                                                v-model="section_header_ids"
                                                                item-value="section_id"
                                                                item-text="section_name"
                                                                :items="sectionheadersdata"
                                                                :label="'Search By Section Header' | translate"
                                                                 autocomplete="off"
                                                                 prepend-icon="search"
                                                                 :rules="documentRules"
                                                                 chips
                                                                 small-chips
                                                                 multiple
                                                                 required
                                                                 @change="filterbySectionHeaders()"
                                                                 >
                                                                </v-select>
                                                           </v-col>
                                                        </v-row>

                                                        <v-row>
                                                           <v-col cols="12" sm="12">
                                                              <v-select
                                                                 v-model="general_documents"
                                                                 :items="generaldocuments"
                                                                 item-value="gendoc_id"
                                                                 item-text="gendoc_name"
                                                                 autocomplete="off"
                                                                 outlined
                                                                :label="'General Documents' | translate"
                                                                 :rules="documentRules"
                                                                 chips
                                                                 small-chips
                                                                 multiple
                                                                 required
                                                                 >
                                                                 <template v-slot:selection="{ item, index }">
                                                                    <v-chip>
                                                                      <span>
                                                                        <v-avatar
                                                                          right
                                                                          class="primary mr-2 white--text"
                                                                        >
                                                                          {{ index+1 }}
                                                                        </v-avatar>
                                                                        {{ item.gendoc_name }}</span>
                                                                    </v-chip>
                                                                  </template>
                                                                </v-select>
                                                           </v-col>
                                                        </v-row>
                                                     </v-form>
                                                  </v-container>
                                               </v-card-text>
                                            </v-card>
                                         </v-tab-item>
                                         <v-tab-item
                                            value="certifications"
                                            >
                                            <v-card flat class="mb-8">
                                               <v-card-title>Add Certificate</v-card-title>
                                               <v-container>
                                                  <v-row>
                                                     <v-col cols="12" sm="12">
                                                        <v-select
                                                           v-model="cert_id"
                                                           :items="certificatetemplates"
                                                           item-value="cert_id"
                                                           item-text="cert_name"
                                                           autocomplete="off"
                                                           name="cert_id"
                                                           outlined
                                                           chips
                                                           small-chips
                                                           :label="'Certificate' | translate"
                                                           clearable
                                                           hide-details
                                                           ></v-select>
                                                     </v-col>
                                                  </v-row>
                                               </v-container>
                                            </v-card>
                                         </v-tab-item>
                                      </v-tabs-items>
                                   </v-col>
                                </v-row>
                             </v-col>
                          </v-row>
                       </v-form>
                       <v-btn
                          dark
                          fab
                          fixed
                          bottom
                          right
                          large
                          color="pink"
                          class="mx-2"
                          @click="validate"
                          >
                          <v-icon>mdi-content-save</v-icon>
                       </v-btn>
                    </v-card-text>
                 </v-container>
              </v-card>
              <v-dialog
                 v-model="showConfirmationDialog"
                 persistent
                 >
                 <v-card>
                    <v-card-title>Confirm Publish</v-card-title>
                    <v-card-text>Once a config is published, you cannot edit this again</v-card-text>
                    <v-card-actions>
                       <v-spacer></v-spacer>
                       <v-btn color="blue darken-1" text @click="closeConfirmationDialog()">{{ 'Close' | translate }}</v-btn>
                       <v-btn color="blue darken-1" text @click="changeToDraft()">{{ 'Save as Draft' | translate}}</v-btn>
                       <v-btn color="blue darken-1" text v-if="course_action=='edit'" @click="update">{{ 'Publish'| translate }}</v-btn>
                       <v-btn color="blue darken-1" text v-else @click="submit">{{ 'Publish'| translate }}</v-btn>
                    </v-card-actions>
                 </v-card>
              </v-dialog>
              <v-dialog v-model="addCertificationDialog" refs="certificatemodal" :fullscreen='certificatefilevalue == null || editCertificateStatus == false ? false : true' persistent >
                 <v-card>
                    <v-card-title>
                       <span class="headline">{{ 'Add certifications' | translate }}</span>
                    </v-card-title>
                    <v-card-text>
                       <v-form id="certificationform" ref="certificationform" class="mt-2">
                          <v-row v-if="Object.keys(certificationerror).length > 0" class="certificationerrors">
                             <v-col cols="12">
                                <v-alert
                                   dense
                                   v-for="(v, k) in certificationerror"
                                   :key="k"
                                   outlined
                                   type="error"
                                   >{{ k }} {{ v }}</v-alert>
                             </v-col>
                          </v-row>
                          <v-row>
                             <v-col cols="6" sm="6">
                                <v-text-field
                                   name="cert_name"
                                   outlined
                                   v-model="cert_name"
                                   :rules="certificateNameRules"
                                   :label="'Certificate name' | translate"
                                   required
                                   ></v-text-field>
                             </v-col>
                             <v-col v-if="editCertificateStatus == false" cols="6" sm="6">
                                <v-file-input
                                   id="certificate_file"
                                   name="cert_file"
                                   ref="cert_file"
                                   v-model="certificatefilevalue"
                                   outlined
                                   chips
                                   required
                                   :rules="certificateRules"
                                   :label="'Select certificate' | translate "
                                   @change="onFileSelected"
                                   ></v-file-input>
                             </v-col>
                          </v-row>
                          <v-row v-if="certificatefilevalue || editCertificateStatus">
                             <v-col cols="5">
                                <v-row>
                                   <v-col cols="7">
                                      <v-select
                                         v-model="selectedFont"
                                         :items="fontValues"
                                         item-value="value"
                                         item-text="text"
                                         autocomplete="off"
                                         outlined
                                         :label="'Font' | translate"
                                         :rules="fontRules"
                                         chips
                                         small-chips
                                         required
                                         ></v-select>
                                   </v-col>
                                   <v-col cols="5">
                                      <v-select
                                         v-model="fontsize"
                                         :items="fontsizeValues"
                                         autocomplete="off"
                                         outlined
                                         :label="'Font size' | translate"
                                         :rules="fontsizeRules"
                                         chips
                                         small-chips
                                         required
                                         ></v-select>
                                   </v-col>
                                </v-row>
                             </v-col>
                             <v-col cols="7">
                                <v-row>
                                   <v-col cols="9">
                                      <div class="text-center">
                                         <v-pagination
                                            v-model="currentPage"
                                            :length="pageCount"
                                            ></v-pagination>
                                      </div>
                                   </v-col>
                                   <v-col cols="3">
                                      <v-btn :disabled="previewButtonStatus" color="primary" @click="preview()">{{ 'Preview' | translate }}</v-btn>
                                   </v-col>
                                </v-row>
                             </v-col>
                          </v-row>
                          <v-row v-if="certificatefilevalue || editCertificateStatus" >
                             <v-col cols="12" id="pdfManager">
                                <div class="" id="selectorContainer">
                                   <div class="col-fixed-240" id="parametriContainer">
                                   </div>
                                   <div class="col-fixed-605">
                                      <div id="pageContainer" class="pdfViewer singlePageView dropzone nopadding" style="background-color:transparent">
                                         <pdf id="the-canvas" :page="currentPage"   :src='cert_link'></pdf>
                                      </div>
                                   </div>
                                </div>
                             </v-col>
                          </v-row>
                       </v-form>
                    </v-card-text>
                    <v-card-actions>
                       <v-spacer></v-spacer>
                       <v-btn color="blue darken-1" text @click="closeCertificationBox()">{{ 'Close' | translate }}</v-btn>
                       <v-btn color="blue darken-1" v-if="editCertificateStatus" text @click="updateCertification()">{{ 'Upload' | translate}}</v-btn>
                       <v-btn color="blue darken-1" v-else text @click="addCertification()">{{ 'Upload'| translate }}</v-btn>
                    </v-card-actions>
                 </v-card>
              </v-dialog>
              <v-dialog v-if='!loadingdata' v-model="dialogbox" persistent max-width="500px">
                 <v-card>
                    <v-card-title>
                       <span class="headline">{{ 'Add General documents' }}</span>
                    </v-card-title>
                    <v-card-text>
                       <v-container>
                          <v-form id="modelform" ref="modelform">
                             <v-row>
                                <v-col v-if="documenterrors" cols="12" sm="12" md="12">
                                   <v-alert
                                      dense
                                      v-for="(v, k) in documenterrors"
                                      :key="k"
                                      outlined
                                      type="error"
                                      >{{ k }} {{ v }}</v-alert>
                                </v-col>
                             </v-row>
                             <v-row>
                                <v-col cols="12" sm="12">
                                   <v-select
                                      v-model="general_documents"
                                      :items="generaldocuments"
                                      item-value="gendoc_id"
                                      item-text="gendoc_name"
                                      autocomplete="off"
                                      outlined
                                      label="General Documents"
                                      :rules="documentRules"
                                      chips
                                      small-chips
                                      multiple
                                      required
                                      ></v-select>
                                </v-col>
                             </v-row>
                          </v-form>
                       </v-container>
                    </v-card-text>
                    <v-card-actions>
                       <v-btn text color="blue darken-1" @click='gotoaddDocument()'>Add new document</v-btn>
                       <v-spacer></v-spacer>
                       <v-btn color="blue darken-1" text @click="dialogbox=false">{{ 'Close' }}</v-btn>
                       <v-btn color="blue darken-1" text @click="addDocument">{{ 'Upload' }}</v-btn>
                    </v-card-actions>
                 </v-card>
              </v-dialog>
              <Dialogbox
                 @closing="closing"
                 @deletedata="documentDelete"
                 :title="dialogboxtitle"
                 :dialog="dialogstatus"
                 :buttonTitle="dialogButtontitle"
                 v-if="dialogstatus"
                 />
              <Dialogbox
                 @closing="closingCertificateBox"
                 @deletedata="documentCertificateDelete"
                 :title="dialogboxtitle"
                 :dialog="dialogcertificatestatus"
                 :buttonTitle="dialogButtontitle"
                 v-if="dialogcertificatestatus"
                 />
           </v-col>
        </v-row>
     </v-container>
  </v-main>
</template>
<script>
  import {
    ADD_COURSE_VERSIONS, 
    UPDATE_COURSE_VERSION,
    FETCH_COURSE_DOCUMENTS,
    FETCH_INDIVIDUAL_COURSE_CONFIG,
    UPDATE_COURSE,
    DELETE_GENERAL_DOCUMENT,
    DELETE_CERTIFICATE_TEMPLATE,
    DOWNLOAD_GENERAL_DOCUMENT,
    FETCH_GENERAL_DOCUMENTS,
    FETCH_CERTIFICATE_TEMPLATE,
    CREATE_CERTIFICATE_TEMPLATE,
    UPDATE_CERTIFICATE_TEMPLATE,
    DOCUMENTS_RESET_STATE,
    COURSE_RESET_STATE,
    PREVIEW_CERTIFICATE_TEMPLATE,
    FETCH_LANGUAGES,
    FETCH_AUTHORS,
    FETCH_MODULES,
    FETCH_TEST_EXAM,
    FETCH_SURVEY,
    FETCH_EXERCISES,
    FETCH_CALENDAR_USERS,
    FETCH_SECTION_HEADER_TAGS,
  } from "@/store/actions.type";
  import interact from "interactjs";
  import pdf from 'vue-pdf'
  import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
  import store from "@/store";
  import { SET_LOADING, SET_SNACKBAR, CLEAR_TEMPLATE_ERROR  } from "@/store/mutations.type";
  import { mapGetters } from "vuex";
  import { mapState } from "vuex";
  
  export default {
    name: "addeditCourseVersions",
    async beforeRouteUpdate(to, from, next) {
      await store.commit(SET_LOADING, true);  
      await store.dispatch(COURSE_RESET_STATE);
      await store.dispatch(DOCUMENTS_RESET_STATE);
      return next();
    },
    async beforeRouteEnter(to, from, next) {
      await store.dispatch(COURSE_RESET_STATE);
      await store.dispatch(DOCUMENTS_RESET_STATE);
      await store.dispatch(FETCH_LANGUAGES,'');
      await store.dispatch(FETCH_AUTHORS);
      await store.dispatch(FETCH_SECTION_HEADER_TAGS,'');
  
      Promise.all([
        await store.dispatch(FETCH_COURSE_DOCUMENTS),
        await store.dispatch(FETCH_CERTIFICATE_TEMPLATE,"?status=active"),
        await store.dispatch(FETCH_MODULES,"?status=active"),
  
        await store.dispatch(FETCH_TEST_EXAM, "?type=exam"),
        await store.dispatch(FETCH_TEST_EXAM, "?type=test"),
        await store.dispatch(FETCH_EXERCISES),
        await store.dispatch(FETCH_SURVEY),
        await store.dispatch(FETCH_CALENDAR_USERS),
        await store.dispatch(FETCH_GENERAL_DOCUMENTS),
      ]).finally(()=>{
        store.commit(SET_LOADING, false);
      })
  
      if (to.params.id) {
        Promise.all([
          await store.dispatch(FETCH_INDIVIDUAL_COURSE_CONFIG, to.params.id),
        ]);
      }
      store.commit(SET_LOADING, false);
      return next();
    },
    async beforeRouteLeave(to, from, next) {
      this.routechange = 'changed'
      await store.commit(SET_LOADING, true);
      await store.dispatch(COURSE_RESET_STATE);
      await store.dispatch(DOCUMENTS_RESET_STATE);
      next();
    },
    components: {
      Dialogbox: () => import("@/components/dialog/dialog"),
      pdf,
      TiptapVuetify
    },
    computed: {
      ...mapGetters(["individualcourseconfig",'loadingdata', 'certificatetemplates', 'coursedocuments','generaldocuments','languages','authors','modules', 'surveys', 'testdata', 'examdata','exercises','sectionheadertags']),
      ...mapState({
        errors: state => state.courses.courseerrors,
        documenterrors: state => state.documents.documenterrors,
        certificateerrors: state => state.certificationtemplates.certificateerrors,
      }),
      sectionheadersdata(){
        return this.sectionheadertags
      },
      fontsizeValues () {
        let data = [];
        for(let i=8; i<=100; i++){
          data.push(i)
        }
        return data;
      }
    },
    watch: {
      dialogbox(val) {
        if (!val) {
          this.$store.state.documents.documenterrors = null
        }
      },
      currentPage(newValue, oldValue){
        if(newValue !== oldValue){
          this.getCoordinates(oldValue)
          this.renderizzaPlaceholder(newValue)
        }
      }
    },  
    data: function() {
      return {
        tabs: null,
        headertabs: null,
        certificationtitle: 'Certifications',
        abbreviation: null,
        language: null,
        author_name: [],
        glossary: null,
        learn: null,
        requirement: null,
        long_description: null,
        certificate_description: null,
        pxtomm: 0.2645833333,
        certificationerror : {},
        addCertificationDialog: false,
        dialogcertificatestatus: false,
        previewButtonStatus: false,
        cert_link: null,
        cert_name: null,
        course_name: null,
        fontsize:null,
        description: null,
        course_id: null,
        cert_file: null,
        cert_id: null,
        selectedFont: null,
        documentdeleteid: null,
        editCertificateStatus: false,
        certificatedeleteid: {},
        visiblestatus: true,
        currentPage: 1,
        pageCount: 0,      
        order: null,
        thumbnail: null,
        certificatefilevalue: null,
        url:null,
        general_documents: [],
        routechange: 'notchanged',
        title: 'General documents',
        dialogbox: false,
        dialogstatus: false,
        cert_meta_data : {},
        course_type: null,
        course_status: 'draft', //default
        section_header_ids: [],
  
        config_version: null,
        course_config_id: null,
        accounttype: null,
        config_description: null,
        items: [],
        coursesdata: [],
        searchData: {},
        prev_course_version_id: null,
  
        price_id: null,
        stripe_product_id: null,
        voucher_product_id: null,
        voucher_price_id: null,
        coupon_price_id: null,
        has_sort: false,
        subscription_expiry_days: 120,
        company_tests: null,
        company_surveys: null,
        company_mandatory_exercises: [],
        company_training_exercises: [],
        company_modules: [],
        company_events: null,
        event_key_description: null,
        chipList: [],
        storage_url: null,
        survey_id: null,
  
        selectedCourseModTestId:[],
        selectedCourseExamId:[],
        selectedCourseTrainingExercises:[],
        selectedCourseMandatoryExercises:[],
        selectedCourseSurveyId:null,
        // selectedCourseModTestId: {},
        typedWord: null,
        modulesdata: {},
        showConfirmationDialog: false,
        course_action: 'new',

        course_docs: [],

  
        datameta:[],
        extensions: [
          [Heading, {
            options: {
              levels: [1, 2, 3,4]
            }
          }],
          Paragraph, 
          BulletList,
          OrderedList,                
          Bold,
          Italic,
          Underline,                       
          Blockquote,        
          Link,
          Strike,
          ListItem,
          HorizontalRule,
          HardBreak,
          History,        
        ],
        fontValues:[
          { value:'HurmeGeometricSans3-Light', text: 'HurmeGeometricSans3-Light', variant: ''},
          { value:'HurmeGeometricSans3-LightOblique', text: 'HurmeGeometricSans3-LightOblique' , variant: ''},
          { value:'HurmeGeometricSans3-Bold', text: 'HurmeGeometricSans3-Bold', variant: ''},
          { value:'HurmeGeometricSans3-BoldOblique', text: 'HurmeGeometricSans3-BoldOblique', variant: ''},
          { value: 'SourceSansPro-Regular', text:'SourceSansPro', variant: ''},
          { value: 'TimesNewRoman', text:'TimesNewRoman', variant: ''},
          { value: 'AvenirLTStd-Book', text:'AvenirLTStd-Book', variant: ''},
          { value: 'AvenirLTStd-Black', text:'AvenirLTStd-Black', variant: ''},
          { value: 'AvenirLTStd-Roman', text:'AvenirLTStd-Roman', variant: ''},
        ],
        dragDropValues:[
          {"fieldName":"salutation", "id": 1},
          {"fieldName":"name", "id": 2}, 
          {"fieldName":"date", "id": 3},
          {"fieldName":"date", "id": 4},
          {"fieldName":"certificate_description", "id": 5}
        ],
        nameRules: [v => !!v || "Course name is required"],
        certificateNameRules: [v => !!v || "Certificate name is required"],
        documentRules: [v => !!v || "General document is required"],
        fontRules: [v => !!v || "Font is required"],
        fontsizeRules: [v => !!v || "Font size is required"],
        certificateRules: [
          v => !!v || 'Certificate is required',
          v => (v && v.size > 0) || 'Certificate is required',
        ],
        descriptionRules: [v => !!v || "Course description is required"],
        
        // coursetypeRules: [v => !!v || "Course Type is required"],
        coursestatusRules: [v => !!v || "Course status is required"],
        abbreviationRules: [v => !!v || "Abbreviation is required"],
        languageRules: [v => !!v || "Language is required"],
        authornameRules: [v => !!v || "Author name is required"],
        certificatedescriptionRules: [v => !!v || "Certificate description is required"],
        course_domain: "learn-ndt.com",
        domainRules: [v => !!v || "Course domain is required"],
        dateRules:
        [
          v => !!v || 'Subscription End Date is required.',
          v => Number.isInteger(Number(v)) || 'Please enter a valid integer for Subscription End Date',
        ],
        domain_values: [],
        course_type_values: [
        { value: "internal", name: this.$t("Internal User") },
        { value: "external", name: this.$t("External User") }
        ],
        course_status_values: [
        { value: "published", name: this.$t("Publish") },
        { value: "draft", name: this.$t("Draft") }
        ],
        headers: [
            { text: this.$t('File name'), value: 'gendoc_name'},
            { text: this.$t('Order'), value: 'order' },
            { text: this.$t('Upload date'), value: 'updated_at' },
            { text: this.$t('Actions'), value: 'action', sortable: false },
          ],
        certificateHeaders: [
            { text: this.$t('Name'), value: 'cert_name'},
            { text: this.$t('Status'), value: 'status' },
            { text: this.$t('Uploaded date'), value: 'created_at' },
            { text: this.$t('Actions'), value: 'action', sortable: false },
          ],
        configdescriptionRules: [v => !!v || "Course config description is required"],
  
          
      };
    },
    async created() {
      this.course_id = this.$route.params.courseid;
      if(this.$route.path.includes('draft')){
        this.course_action = 'draft';
        this.prev_course_version_id= this.$route.params.id;
        this.course_version_id = null;
      }
      else if(this.$route.path.includes('add')){
        this.course_action = 'add';
        this.course_version_id = null;
      }
      else if(this.$route.path.includes('edit')){
        this.course_action = 'edit';
        this.course_version_id = this.$route.params.id;
      }
  
      this.searchData = this.modules;
      this.modulesdata = this.modules;
  
      this.selectedCourseExamId= null;
  
      if (Object.keys(this.individualcourseconfig).length > 0) {
        this.course_name = this.individualcourseconfig.course_name;
        this.description = this.individualcourseconfig.description;
        this.course_id = this.individualcourseconfig.course_id;
  
        this.course_domain = this.individualcourseconfig.course_domain;
        this.general_documents = this.individualcourseconfig.general_documents
        this.course_type = this.individualcourseconfig.course_type
        this.course_status = this.individualcourseconfig.course_status
        this.url  = process.env.VUE_APP_STORAGE_URL + this.individualcourseconfig.course_image
  
        this.abbreviation = this.individualcourseconfig.abbreviation
        this.language = this.individualcourseconfig.lang_id
        this.certificate_description = this.individualcourseconfig.certificate_description
        this.glossary = this.individualcourseconfig.glossary
        this.learn = this.individualcourseconfig.learn
        this.requirement = this.individualcourseconfig.requirement
        this.description = this.individualcourseconfig.description
        this.long_description = this.individualcourseconfig.long_description
        let authordata = []
        this.individualcourseconfig.authors.forEach(function(item) {
          authordata.push(item.author_id)
        });
        this.author_name = authordata
  
        this.config_description = this.individualcourseconfig.config_description
        this.has_sort = this.individualcourseconfig.has_sort
  
        let evemodules = []
        let course_sort = this.individualcourseconfig.has_sort
        this.individualcourseconfig.modules.forEach((item) => {
          evemodules.push({
                  module_id: item.module_id,
                  test_id: item.test_id,
                  title: item.title,
                  sort_sequence: (course_sort) ? item.sort_sequence : null,
              })
          this.selectedCourseModTestId[item.module_id] = item.test_id
        });
        this.company_modules = evemodules
  
        let mandatoryexercises = []
        this.individualcourseconfig.mandatory_exercises.forEach(function(item) {
          mandatoryexercises.push(item.exercise_id)
        });
        this.selectedCourseMandatoryExercises = mandatoryexercises
        this.company_mandatory_exercises = this.selectedCourseMandatoryExercises;
  
        let trainingexercises = []
        this.individualcourseconfig.training_exercises.forEach(function(item) {
          trainingexercises.push(item.exercise_id)
        });
        this.selectedCourseTrainingExercises = trainingexercises
        this.company_training_exercises = this.selectedCourseTrainingExercises;
  
        let couevents = []
        this.individualcourseconfig.events.forEach(function(item) {
          couevents.push({
            event_key: item.event_key,
            event_key_description: item.event_key_description,
          })
        });
        this.chipList = couevents
  
        let generaldocs = []
        this.individualcourseconfig.general_documents.forEach(function(item) {
          generaldocs.push(item.gendoc_id)
        });
        this.general_documents = generaldocs;
  
        this.selectedCourseSurveyId = this.individualcourseconfig.survey_id
        this.selectedCourseExamId = this.individualcourseconfig.test_id
        this.subscription_expiry_days = this.individualcourseconfig.subscription_expiry_days
  
        this.cert_id = this.individualcourseconfig.certificate_template? this.individualcourseconfig.certificate_template.cert_id : null;
        
      }
    },
    mounted() {
      this.constructDomainList();
    },
    methods: {
      constructDomainList() {
        const validDomainsString = process.env.VUE_APP_COMPANY_VALID_DOMAINS;
        const validDomains = JSON.parse(validDomainsString);
        this.domain_values = validDomains.map(domain => ({
          value: domain,
          name: domain
        }));
      },
      filterbySectionHeaders(){
        store.dispatch(FETCH_GENERAL_DOCUMENTS,"?section_header_ids="+this.section_header_ids).then(resp => {
            this.generaldocuments = resp.data;
            this.$store.commit(SET_LOADING, false)
          });
        
      },
      getPdf(pdfData){
          let byteCharacters = atob(pdfData);
          let byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          let byteArray = new Uint8Array(byteNumbers);
          let file = new Blob([byteArray], { type: 'application/pdf;base64' });
          let fileURL = URL.createObjectURL(file);
          return fileURL;
      },
      async handlePreview (data){
          this.$store.dispatch(PREVIEW_CERTIFICATE_TEMPLATE,'preview?cert_id='+data+'&course_id='+this.course_id).then(resp => {
            let fileURL = this.getPdf(resp.data);
            window.open(fileURL);
            this.$store.commit(SET_LOADING, false)
          });
      },
      changeModule() {
        if (this.accounttype == "full-version") {
          this.courseData = this.courseData.map(item => {
            return {
              course_id: item.course_id,
              course_name: item.course_name,
              price_id: '',
              stripe_product_id: '',
              voucher_product_id: '',
              voucher_price_id: '',
              coupon_price_id: '',
              has_sort: false,
              subscription_expiry_days: null,
              company_tests: item.company_tests,
              company_surveys: item.company_surveys,
              company_mandatory_exercises: item.company_mandatory_exercises,
              company_training_exercises: item.company_training_exercises,
              company_modules: item.company_modules,
            };
          });
        }
      },
      addPlan(index, id) {
        this.courseData[index].price_id = id;
      },
      addProduct(index, productid) {
        this.courseData[index].stripe_product_id = productid
      },
      addVoucherProduct(index, id) {
        this.courseData[index].voucher_product_id = id
        this.courseData[index].voucher_price_id = null
        this.courseData[index].coupon_price_id = null
      },
      addVoucherPrice(index, id) {
        this.courseData[index].voucher_price_id = id
      },
      addDiscountPrice(index, id) {
        this.courseData[index].coupon_price_id = id
      },
      searchModule(searchQuery) {
        const searchTerm = searchQuery.toLowerCase();
        let data = [];
        data = this.modulesdata.filter((mod) => {
          const moduleTitle = mod.title.toLowerCase();
          return moduleTitle.includes(searchTerm);
        });
  
        return data;
      },
      addTutorProduct(stripe_product_id) {
        this.tutorProductData.stripe_product_id = stripe_product_id
      },
      addTutorPlan(id) {
        this.tutorProductData.price_id = id;
      },
      addTutorVoucherProduct(id) {
        this.tutorProductData.voucher_product_id = id
        this.tutorProductData.voucher_price_id = null
        this.tutorProductData.coupon_price_id = null
      },
      addTutorVoucherPrice(id) {
        this.tutorProductData.voucher_price_id = id
      },
      addTutorDiscountPrice(id) {
        this.tutorProductData.coupon_price_id = id
      },
      onFileSelected() {
        this.cert_meta_data = {}
        this.cert_file = this.certificatefilevalue == null ? null : document.getElementById("certificate_file").files[0]
        this.cert_link = this.cert_file == null ? null : URL.createObjectURL(this.cert_file)
  
        if(this.cert_link !== null ){
          let loadingTask = pdf.createLoadingTask(this.cert_link);
  
          loadingTask.promise.then(pdf => { 
              this.pageCount = pdf.numPages 
              if(this.certificatefilevalue !== null){
                this.fontsize = null
                this.selectedFont = null
                this.pdfDragSetup()
                this.renderizzaPlaceholder()
              }
            });
        }
      },
      editCertificateBox(data){
        
        this.$store.dispatch(PREVIEW_CERTIFICATE_TEMPLATE,'view-file/'+data.cert_id).then(resp => {
          this.editCertificateStatus = true
          this.addCertificationDialog = true        
          this.cert_name = data.cert_name
          this.cert_id = data.cert_id
          this.cert_link = this.getPdf(resp.data)
          this.cert_meta_data = JSON.parse(data.cert_meta_data)
          let font_meta_data = JSON.parse(data.font_meta_data)
          this.fontsize = font_meta_data["font-size"]
          this.selectedFont = font_meta_data.font
          for (var key in this.cert_meta_data) {
              this.cert_meta_data[key].forEach((data,index) => {
                this.cert_meta_data[key][index].positionX = (data.positionX*3.7795275591)
                this.cert_meta_data[key][index].positionY = (data.positionY*3.7795275591)
              })
          }
          let loadingTask = pdf.createLoadingTask(this.cert_link);
  
          loadingTask.promise.then(pdf => { 
              this.pageCount = pdf.numPages 
            });
  
          this.pdfDragSetup()
          this.renderizzaPlaceholder(this.currentPage)
  
          this.$store.commit(SET_LOADING, false)
        }) 
  
      },
      renderizzaEditPlaceholder(paramsData){
  
      let parametri = paramsData
            
      let htmlData = ''      
      for (let i = 0; i < parametri.length; i++) {
        let param = parametri[i];
  
  
        let classStyle = "";
        classStyle = "drag-drop can-drop";
        htmlData+= '<div class="'+classStyle+'" data-id="'+param.id+'" data-desc="'+param.fieldName+'" data-x="'+((param.positionX/this.pxtomm)+240 -6)+'" data-y="'+((param.positionY/this.pxtomm))+'" style="transform: translate('+((param.positionX/this.pxtomm)+240-6)+'px, '+((param.positionY/this.pxtomm))+'px); ">  <span class="circle"></span><span class="descrizione">'+param.fieldName+' </span></div>'
        }
        this.$nextTick(()=>{
          document.getElementById('parametriContainer').innerHTML= htmlData
        })
      
      }, 
      openCertificationbox(){
        this.addCertificationDialog = true
      },
      pdfDragSetup(){
          interact('.drag-drop')
          .draggable({
          inertia: true,
          restrict: {
            restriction: "#selectorContainer",
            endOnly: true,
            elementRect: { top: 0, left: 0, bottom: 1, right: 1 }
          },
          autoScroll: true,
          // dragMoveListener from the dragging demo above
          onmove: function (event) {
            var target = event.target,
            x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx,
            y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;
            target.style.webkitTransform =
            target.style.transform ='translate(' + x + 'px, ' + y + 'px)';
            target.setAttribute('data-x', x);
            target.setAttribute('data-y', y);
            }
          });
  
          interact('.dropzone').dropzone({
            accept: '.drag-drop',
            overlap: 1,
            ondropactivate: function (event) {
              event.target.classList.add('drop-active');
            },
            ondragenter: function (event) {
              var draggableElement = event.relatedTarget,
              dropzoneElement = event.target;
              dropzoneElement.classList.add('drop-target');
              draggableElement.classList.add('can-drop');
              draggableElement.classList.remove('dropped-out');
            },
            ondragleave: function (event) {
              event.target.classList.remove('drop-target');
              event.relatedTarget.classList.remove('can-drop');
              event.relatedTarget.classList.add('dropped-out');
            },
            ondropdeactivate: function (event) {
              event.target.classList.remove('drop-active');
              event.target.classList.remove('drop-target');
            }
          });
  
     },    
     renderizzaPlaceholder(newValue){
  
      let parametri = this.dragDropValues
      let yCounterOfGenerated = 0;
      let numOfMaxItem = 25;
      let notValidHeight = 30;
      let y = 0;
      let x = 6;
            
      let htmlData = ''      
      for (let i = 0; i < parametri.length; i++) {
        let param = parametri[i];
        
        if(i > 0 && i%numOfMaxItem == 0){
          yCounterOfGenerated = 0;
        }
  
        let classStyle = "";
        y = yCounterOfGenerated;
        yCounterOfGenerated += notValidHeight;
        classStyle = "drag-drop dropped-out";
        if(this.cert_meta_data['0'+(newValue)] !== undefined && this.cert_meta_data['0'+(newValue)].findIndex(o =>o.id == param.id) > -1){
          let index = this.cert_meta_data['0'+(newValue)].findIndex(o => o.id == param.id)
          let classStyleIn = 'drag-drop can-drop'
          let fieldName = this.cert_meta_data['0'+(newValue)][index].fieldName
          let id = this.cert_meta_data['0'+(newValue)][index].id
          let x = this.cert_meta_data['0'+(newValue)][index].positionX
          let y = this.cert_meta_data['0'+(newValue)][index].positionY
          htmlData+='<div class="'+classStyleIn+'" data-id="'+id+'" data-desc="'+fieldName+'" data-x="'+(x+240)+'" data-y="'+(y)+'" style="transform: translate('+(x+240)+'px, '+(y)+'px); ">  <span class="circle"></span><span class="descrizione">'+fieldName+' </span></div>'
        }else{
          htmlData+= '<div class="'+classStyle+'" data-id="'+param.id+'" data-desc="'+param.fieldName+'" data-x="'+x+'" data-y="'+y+'" style="transform: translate('+x+'px, '+y+'px); ">  <span class="circle"></span><span class="descrizione">'+param.fieldName+' </span></div>'
        }
        }
        this.$nextTick(()=>{
          document.getElementById('parametriContainer').innerHTML= htmlData
        })
  
      }, 
      getCoordinates (pageCountValue) {
          let paramContainerWidth = document.getElementById("parametriContainer").offsetWidth
          //let cert_meta_data = []
          document.getElementsByClassName('drag-drop can-drop').forEach((element) =>{
            let x = parseFloat(element.getAttribute('data-x'));
            let y = parseFloat(element.getAttribute('data-y'));
            let id = parseFloat(element.getAttribute('data-id'));
            let descrizione = element.getAttribute('data-desc');
            let posizioneY = y;	  
            let posizioneX = (x - paramContainerWidth);
            let val = {"id":  id , "fieldName": descrizione, "positionX":posizioneX,   "positionY":posizioneY, };
            if(this.cert_meta_data['0'+(pageCountValue)] !== undefined){
              let index = this.cert_meta_data['0'+(pageCountValue)].findIndex(o =>o.id == id)
              if(index > - 1){
                this.cert_meta_data['0'+(pageCountValue)][index] = val
              }
              else{
                this.cert_meta_data['0'+(pageCountValue)].push(val);
              }
            } else {
              this.cert_meta_data['0'+(pageCountValue)]=[val]
            }       
          })
      },
      async preview(){
          let formData = new FormData(document.getElementById("certificationform"))
          this.getCoordinates(this.currentPage);
          if(Object.keys(this.cert_meta_data).length == 0){
            this.certificationerror = {'Fields': 'Please drop fields on PDF'}
            return;
          }
          if(this.selectedFont == null){
            this.certificationerror = {'Fields': 'Please select font'}
            return;
          }
          if(this.fontsize == null){
            this.certificationerror = {'Fields': 'Please select font size'}
            return;
          }
          let certmetadata = JSON.parse(JSON.stringify(this.cert_meta_data));
          for (var key in certmetadata) {
              certmetadata[key].forEach((data,index) => {
                certmetadata[key][index].positionX = data.positionX*this.pxtomm
                certmetadata[key][index].positionY = data.positionY*this.pxtomm
              })
          }
          let font_meta_data= {"font": this.selectedFont, "font-variant": this.fontValues.find(o => o.value === this.selectedFont).variant, "font-size": this.fontsize};
  
          formData.set('cert_name', this.cert_name)  
          formData.set("cert_meta_data", JSON.stringify(certmetadata));
          formData.set('course_id', this.course_id) 
          formData.set('font_meta_data', JSON.stringify(font_meta_data));
          if(this.editCertificateStatus){
            formData.set('cert_id', this.cert_id) 
          } 
          else{
            formData.set("cert_file", this.cert_file);
          }
          
          this.$store.dispatch(CREATE_CERTIFICATE_TEMPLATE,{ slug:'preview', data:formData}).then(resp => {
            var byteCharacters = atob(resp.data);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var file = new Blob([byteArray], { type: 'application/pdf;base64' });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            this.$store.commit(SET_LOADING, false)
          });
      },
  
      async addCertification(){
        if (this.$refs.certificationform.validate()) {
          let formData = new FormData(document.getElementById("certificationform"))
          this.getCoordinates(this.currentPage);
          if(this.cert_meta_data.length == 0){
            this.certificationerror = {'Fields': 'Please drop fields on PDF'}
            return;
          }
  
          let certmetadata = JSON.parse(JSON.stringify( this.cert_meta_data));
          for (var key in certmetadata) {
              certmetadata[key].forEach((data,index) => {
                certmetadata[key][index].positionX = data.positionX*this.pxtomm
                certmetadata[key][index].positionY = data.positionY*this.pxtomm
              })
          }
  
  
          let font_meta_data= {"font": this.selectedFont, "font-variant": this.fontValues.find(o => o.value === this.selectedFont).variant, "font-size": this.fontsize};
          this.$store.commit(SET_LOADING, true)
          formData.set('cert_name', this.cert_name)  
          formData.set("cert_meta_data", JSON.stringify(certmetadata));
          formData.set('course_id', this.course_id)  
          formData.set("cert_file", this.cert_file);
          formData.set('font_meta_data', JSON.stringify(font_meta_data));
          this.$store.dispatch(CREATE_CERTIFICATE_TEMPLATE, { slug:'', data:formData}).then( resp => {
            if(resp.success !== undefined && resp.success){
                this.$store.dispatch(FETCH_CERTIFICATE_TEMPLATE,'?course_id='+this.course_id).then(tempresp=>{
                  if(tempresp.success !== undefined && tempresp.success){
                    this.$store.commit(SET_SNACKBAR, {status: true, message: 'Certificate added successfully'})
                    this.$store.commit(SET_LOADING, false)
                    this.closeCertificationBox()
                  }
                  else {
                    this.$store.commit(SET_LOADING, false)
                  }
                })
            }
            else{
              this.$store.commit(SET_LOADING, false)
            }
          })
        }
      },
      async updateCertification(){
        if (this.$refs.certificationform.validate()) {
          let formData = new FormData(document.getElementById("certificationform"))
          this.getCoordinates(this.currentPage);
          if(this.cert_meta_data.length == 0){
            this.certificationerror = {'Fields': 'Please drop fields on PDF'}
            return;
          }
          let certmetadata = JSON.parse(JSON.stringify( this.cert_meta_data));
          for (var key in certmetadata) {
              certmetadata[key].forEach((data,index) => {
                certmetadata[key][index].positionX = data.positionX*this.pxtomm
                certmetadata[key][index].positionY = data.positionY*this.pxtomm
              })
          }
          let font_meta_data= {"font": this.selectedFont, "font-variant": this.fontValues.find(o => o.value === this.selectedFont).variant, "font-size": this.fontsize};
          this.$store.commit(SET_LOADING, true)
          formData.set('cert_name', this.cert_name)  
          formData.set("cert_meta_data", JSON.stringify(certmetadata));
          formData.set('course_id', this.course_id)  
          formData.set('font_meta_data', JSON.stringify(font_meta_data));
          formData.set('_method','put')
          
          this.$store.dispatch(UPDATE_CERTIFICATE_TEMPLATE, { slug:this.cert_id, data:formData}).then( resp => {
            if(resp.success !== undefined && resp.success){
                this.$store.dispatch(FETCH_CERTIFICATE_TEMPLATE,'?course_id='+this.course_id).then(tempresp=>{
                  if(tempresp.success !== undefined && tempresp.success){
                    this.$store.commit(SET_SNACKBAR, {status: true, message: 'Certificate updated successfully'})
                    this.$store.commit(SET_LOADING, false)
                    this.closeCertificationBox()
                  }
                  else {
                    this.$store.commit(SET_LOADING, false)
                  }
                })
            }
            else{
              this.$store.commit(SET_LOADING, false)
            }
          })
        }
      },
  
      closeCertificationBox(){
        this.$refs.certificationform.resetValidation()
        this.$refs.certificationform.reset()    
        interact('.dropzone').unset()  
        interact('.drag-drop').unset()
        this.$store.commit(CLEAR_TEMPLATE_ERROR)
        this.cert_name = ''
        this.cert_file = ''
        this.cert_link = ''
        this.cert_id = ''
        this.selectedFont = ''
        this.fontsize = ''
        this.editCertificateStatus = false
        this.cert_meta_data = null
        this.certificationerror = {}
        this.addCertificationDialog = false
      },
      onThumbnailSelected() {
        this.thumbnail = document.getElementById("thumbnail").files[0];
        this.url = URL.createObjectURL(this.thumbnail);
      },
      gotoaddDocument(){
        this.$router.push({ path: '/general-documents/add', query: { course_id: this.course_id } })
      },
      deleting(id) {
        this.dialogstatus = true
        this.dialogboxtitle = "Are you sure want to remove?"
        this.dialogButtontitle = "Remove"
        this.documentdeleteid = id
      },
      deletingCertificate(id) {
        this.dialogcertificatestatus = true
        this.dialogboxtitle = "Are you sure want to remove?"
        this.dialogButtontitle = "Remove"
        this.certificatedeleteid = id
      },
      
      async openDocumentbox() {
        await store.dispatch(FETCH_GENERAL_DOCUMENTS).then(resp => { 
            if(resp.success){
              this.general_documents  = this.individualcourseconfig.general_documents
              store.commit(SET_LOADING, false);                   
            }
        });
        this.dialogbox = true
      },
      async addDocument(){
        if (this.$refs.modelform.validate()) {
            var formData = new FormData(document.getElementById("modelform"))
              formData.set('course_id', this.course_id)  
              formData.set('description', this.description)
              this.general_documents.forEach(function(item) {
                  formData.append("general_documents[]", item)
              });
              formData.append('_method', 'put')
              this.$store.dispatch(UPDATE_COURSE, formData).then(async resp => {
                this.general_documents  = resp.data.general_documents
                if (resp.success) {
                  await store.dispatch(FETCH_COURSE_DOCUMENTS, '?course_id='+this.course_id).then( resp =>{
                    if (resp.success) {
                      store.commit(SET_SNACKBAR, {status: true, message: 'General Documents added successfully'})
                      store.commit(SET_LOADING, false)
                      this.dialogbox = false
                    }
  
                  })
  
                }
              });               
        }     
      },
      changeToDraft(){
        if(this.course_action=='draft' && this.course_status=='published'){
          this.course_status = 'draft'
          this.submit();
        }
        else if(this.course_action=='add' && this.course_status=='published'){
          this.course_status = 'draft'
          this.submit();
        }
        else if(this.course_action=='edit' && this.course_status=='published'){
          this.course_status = 'draft'
          this.update();
        }
      },
      validate: function(){
        if (this.$refs.form.validate()) {
          if(this.course_action==='edit' && this.course_status==='draft' && this.course_version_id){
            this.update();
          }
          else if(this.course_status=='draft' && this.course_id){
            this.course_status = 'draft'
            this.submit();
          }
          else{
            this.showConfirmationDialog = true;
          }
        }
      },
      submit: function() {
        if (this.$refs.form.validate()) {
          this.showConfirmationDialog = false;
  
          var formData = new FormData();
  
          if(this.$route.path.includes('draft')){
            formData.append("prev_course_version_id", this.individualcourseconfig.course_version_id)
          }

          if (this.thumbnail !== null ) {
            formData.set("course_image", this.thumbnail)     
          }
          formData.set('course_id', this.course_id)
          formData.set('course_name', this.course_name)
          formData.set("course_domain", this.course_domain)
          formData.set('course_type', this.course_type) 
          formData.set('course_status', this.course_status) 
          formData.set("glossary", this.glossary)
          formData.set("requirement", this.requirement)
          formData.set("learn", this.learn)
          formData.set("module_domain", this.module_domain)
          formData.set("description", this.description)
          formData.set("long_description", this.long_description)
          formData.set("certificate_description", this.certificate_description)
          formData.set("abbreviation", this.abbreviation)
  
          formData.set("config_description", this.config_description)
  
          formData.set("price_id", this.price_id)
          formData.set("stripe_product_id", this.stripe_product_id)
          formData.set("voucher_price_id", this.voucher_price_id)
          formData.set("voucher_product_id", this.voucher_product_id)
          formData.set("coupon_price_id", this.coupon_price_id)
          formData.set("has_sort", this.has_sort?1:0)
          this.company_mandatory_exercises.forEach(function(item) {
            formData.append("mandatory_exercises[]", item);
          });
          this.company_training_exercises.forEach(function(item) {
            formData.append("training_exercises[]", item);
          });
  
          this.selectedCourseExamId ? formData.set("test_id", parseInt(this.selectedCourseExamId)) : null
          this.selectedCourseSurveyId ? formData.set("survey_id", parseInt(this.selectedCourseSurveyId)) : null
          this.cert_id ? formData.set("cert_id", parseInt(this.cert_id)) : null
  
          let mods = this.company_modules.map(item => {
              return {
                  module_id: item.module_id,
                  test_id: item.test_id !== null ? parseInt(item.test_id) : null,
                  sort_sequence: item.sort_sequence,
              };
          });
  
          mods.forEach((module, index) => {
              formData.append(`modules[${index}][module_id]`, module.module_id);
              formData.append(`modules[${index}][test_id]`, module.test_id !== null ? module.test_id : ''); // Convert null to an empty string if needed
              formData.append(`modules[${index}][sort_sequence]`, module.sort_sequence);
          });
  
  
          let eventlist = this.chipList.map(item => {
              return {
                  event_key: item.event_key,
                  event_key_description: item.event_key_description,
              };
          });
  
          eventlist.forEach((eve, index) => {
              formData.append(`events[${index}][event_key]`, eve.event_key);
              formData.append(`events[${index}][event_key_description]`, eve.event_key_description);
          });
  
          this.author_name.forEach(function(item) {
            formData.append("authors[]", item);
          });
  
          this.general_documents.forEach(function(item, index) {
              formData.append(`general_documents[${index}][gendoc_id]`, item);
              formData.append(`general_documents[${index}][sort_sequence]`, index+1);
          });
  
          this.$store.dispatch(ADD_COURSE_VERSIONS, formData).then(resp => {
            if (resp && resp.success) {
              store.commit(SET_LOADING, false);
              this.$router.push({
                name: "courseversion",
                params: { id: resp.data.course_id }
              }); 
              store.commit(SET_SNACKBAR, {status: true, message: 'Course added successfully'})
            }
          });
        }
      },
      update: function() {
        if (this.$refs.form.validate()) {
          this.showConfirmationDialog = false;
          var formData = new FormData();
  
          if (this.thumbnail !== null ) {
            formData.set("course_image", this.thumbnail)     
          }
          formData.set('course_id', this.course_id)
          formData.set('course_version_id', this.$route.params.id)
          
          formData.set('course_name', this.course_name)
          formData.set("course_domain", this.course_domain)
          formData.set('course_type', this.course_type) 
          formData.set('course_status', this.course_status) 
          formData.set("glossary", this.glossary)
          formData.set("requirement", this.requirement)
          formData.set("learn", this.learn)
          formData.set("module_domain", this.module_domain)
          formData.set("description", this.description)
          formData.set("long_description", this.long_description)
          formData.set("certificate_description", this.certificate_description)
          formData.set("abbreviation", this.abbreviation)
  
          formData.set("config_description", this.config_description)
  
          formData.set("price_id", this.price_id)
          formData.set("stripe_product_id", this.stripe_product_id)
          formData.set("voucher_price_id", this.voucher_price_id)
          formData.set("voucher_product_id", this.voucher_product_id)
          formData.set("coupon_price_id", this.coupon_price_id)
          formData.set("has_sort", this.has_sort?1:0)
          this.company_mandatory_exercises.forEach(function(item) {
            formData.append("mandatory_exercises[]", item);
          });
          this.company_training_exercises.forEach(function(item) {
            formData.append("training_exercises[]", item);
          });
  
          this.selectedCourseExamId ? formData.set("test_id", parseInt(this.selectedCourseExamId)) : null;
          this.selectedCourseSurveyId ? formData.set("survey_id", parseInt(this.selectedCourseSurveyId)) : null;
          
          this.cert_id ? formData.set("cert_id", parseInt(this.cert_id)) : null
  
          let mods = this.company_modules.map(item => {
              return {
                  module_id: item.module_id,
                  test_id: item.test_id !== null ? parseInt(item.test_id) : null,
                  sort_sequence: item.sort_sequence,
              };
          });
          mods.forEach((module, index) => {
              formData.append(`modules[${index}][module_id]`, module.module_id);
              formData.append(`modules[${index}][test_id]`, module.test_id !== null ? module.test_id : ''); // Convert null to an empty string if needed
              formData.append(`modules[${index}][sort_sequence]`, module.sort_sequence);
          });

          let eventlist = this.chipList.map(item => {
              return {
                  event_key: item.event_key,
                  event_key_description: item.event_key_description,
              };
          });
  
          eventlist.forEach((eve, index) => {
              formData.append(`events[${index}][event_key]`, eve.event_key);
              formData.append(`events[${index}][event_key_description]`, eve.event_key_description);
          });
  
          this.author_name.forEach(function(item) {
            formData.append("authors[]", item);
          });

          this.general_documents.forEach(function(item, index) {
              formData.append(`general_documents[${index}][gendoc_id]`, item);
              formData.append(`general_documents[${index}][sort_sequence]`, index+1);
          });
  
          formData.set('_method','put')
  
          this.$store.dispatch(UPDATE_COURSE_VERSION, formData).then(resp => {
            if (resp && resp.success) {
              store.commit(SET_LOADING, false);
              this.$router.push({
                name: "courseversion",
                params: { id: resp.data.course_id }
              }); 
              store.commit(SET_SNACKBAR, {status: true, message: 'Course version updated successfully'})
            }
          });
        }
      },
      closing(){
        this.dialogstatus = false
      }, 
      closingCertificateBox(){
        this.dialogcertificatestatus = false
      }, 
      async downloadFile(documentdata){
        await this.$store
          .dispatch(DOWNLOAD_GENERAL_DOCUMENT, 'view-file/'+documentdata.docfile_id)
          .then(resp => {
            var fileURL = window.URL.createObjectURL(new Blob([resp]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', documentdata.doc_type+'.'+documentdata.extension);
            document.body.appendChild(fileLink);
            fileLink.click(); 
            store.commit(SET_LOADING, false);
  
          });
      },
      async documentCertificateDelete(){
        await this.$store
          .dispatch(DELETE_CERTIFICATE_TEMPLATE, this.certificatedeleteid)
          .then(async resp => {
            if (resp.success) {
              this.certificatedeleteid = null
              await store.dispatch(COURSE_RESET_STATE)
              await store.dispatch(FETCH_CERTIFICATE_TEMPLATE, '?course_id='+this.course_id)
              await store.dispatch(FETCH_INDIVIDUAL_COURSE_CONFIG, this.course_id).then(resp => {
                if (resp.success) {
                  store.commit(SET_LOADING, false);
                  this.dialogcertificatestatus = false;
                }
              });
            }
          });
      },
      async documentDelete() {
        await this.$store
          .dispatch(DELETE_GENERAL_DOCUMENT, this.documentdeleteid+'?course_id='+this.course_id)
          .then(async resp => {
            if (resp.success) {
              this.documentdeleteid = null
              await store.dispatch(COURSE_RESET_STATE)
              await store.dispatch(FETCH_COURSE_DOCUMENTS, '?course_id='+this.course_id)
              await store.dispatch(FETCH_INDIVIDUAL_COURSE_CONFIG, this.course_id).then(resp => {
                if (resp.success) {
                  this.general_documents  = resp.data.general_documents
                  store.commit(SET_LOADING, false);
                  this.dialogstatus = false;
                }
              });
            }
          });
      },       
      checkModule(module_id) {
        return this.company_modules.some(x => x.module_id === module_id)
      },     
      hasSortChanged(){
          var mod =[]
          var sort_sequence = 1;
          this.company_modules.forEach(item2 => {
              let newmod = {
                  module_id: item2.module_id,
                  test_id: item2.test_id,
                  title: item2.title,
                  sort_sequence: this.has_sort ? sort_sequence++ : null,
              };
              mod.push(newmod);
          });
          this.company_modules = mod;
        },
        onClickSearch() {
          this.searchData = this.searchModule(this.typedWord);
        },
        addModule( data) {
          this.company_modules.push({
                  module_id: data.module_id,
                  test_id: null,
                  title: data.title,
                  sort_sequence: (this.has_sort) ? ((this.company_modules).length)+1 : null,
              })
          this.sortModuleList();
        },
        removeModule(data) {
          this.company_modules = this.company_modules.filter(ids => ids.module_id != data.module_id)
          this.has_sort?this.sortModuleList():'';
        },
        sortModuleList(){
          var mod =[]
          var sort_sequence = 1;
          this.company_modules.forEach(item2 => {
              let newmod = {
                  module_id: item2.module_id,
                  test_id: item2.test_id,
                  title: item2.title,
                  sort_sequence: this.has_sort ? sort_sequence++ : null,
              };
              mod.push(newmod);
          });
          this.company_modules = mod;
        },
        checkIfSelected(moduleid){
          if(this.company_modules.findIndex(module => module.module_id == moduleid)==-1) return true;
          return false;
        },
        addTest( moduleid , test_id) {
          if(test_id!==null){
            this.selectedCourseModTestId[moduleid] = test_id;
            this.company_modules[this.company_modules.findIndex(module => module.module_id == moduleid)].test_id = parseInt(test_id)
          }
          else{
            this.selectedCourseModTestId[moduleid] = null;
            this.company_modules[this.company_modules.findIndex(module => module.module_id == moduleid)].test_id = null
          }
        },
        addExam(test_id) {
          this.test_id = test_id
        },
        addMandatoryExercises(data) {
          var ExerciseArray = [];
          if(data){
            ExerciseArray = data.split(',');
          }
          else{
            this.selectedCourseMandatoryExercises = null;
          }
          this.company_mandatory_exercises = [];
  
          if(ExerciseArray.length){
            ExerciseArray.forEach(exerciseId => {
              this.company_mandatory_exercises.push(exerciseId)
            });
          }
        },
        addTrainingExercises(data) {
          var ExerciseArray = [];
          if(data){
            ExerciseArray = data.split(',');
          }
          else{
            this.selectedCourseTrainingExercises = null;
          }
          this.company_training_exercises = [];
  
          if(ExerciseArray.length){
            ExerciseArray.forEach(exerciseId => {
              this.company_training_exercises.push(exerciseId)
            });
          }
        },
        addSurvey(survey_id) {
          this.company_surveys = survey_id;
        },
        handleInput() {
          this.chipList.push(
            {
              event_key : this.company_events,
              event_key_description : this.event_key_description
            }
          );
          this.company_events = ''; // Clear the input field
          this.event_key_description = ''; // Clear the input field
        },
        removeChip(index) {
          this.chipList.splice(index, 1);
        },
        closeConfirmationDialog(){
          this.showConfirmationDialog = false;
        }
    }
  };
</script>
<style>
  #messageContainer{
  display:none;
  }
  #outer-dropzone {
  height: 140px;
  touch-action: none;
  }
  #inner-dropzone {
  height: 80px;
  }
  .dropzone {
  background-color: #ccc;
  border: dashed 4px transparent;
  border-radius: 4px;
  margin: 10px auto 30px;
  padding: 10px;
  width: 100%;
  transition: background-color 0.3s;
  }
  .drop-active {
  border-color: #aaa;
  }
  .drop-target {
  background-color: #29e;
  border-color: #fff;
  border-style: solid;
  }
  .drag-drop {
  display: inline-block;
  position:absolute;
  z-index:999;
  min-width: 40px;
  padding: 0em 0.5em;
  padding-left:0;
  color: #fff;
  background-color: #29e;
  border: none;
  -webkit-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  transition: background-color 0.3s;
  line-height: 10px;
  padding-right: 0 !important;
  padding-left: 5px !important;
  }
  .drag-drop.can-drop {
  color: #000;
  background-color: transparent;
  opacity:0.9;
  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  /* IE 5-7 */
  filter: alpha(opacity=90);
  /* Netscape */
  -moz-opacity: 0.9;
  /* Safari 1.x */
  -khtml-opacity: 0.9;
  }
  .nopadding {
  padding: 0 !important;
  margin: 0 !important;
  }
  .circle {
  width: 10px;
  height: 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background: #323c3c;
  float: left;
  display: inline-block;
  margin-top: 1px;
  margin-right: 2px;
  }
  .dropped-out{
  display: block;
  padding: .75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.125);
  width:200px;
  color: black;
  }
  .col-fixed-240{
  width:240px;
  height:100%;
  z-index:1;
  }
  .col-fixed-605{
  margin-left:240px;
  width:793.7007874px !important;
  height:100%;
  }
  .page-item{
  cursor:pointer;
  }
  .pager{
  margin-bottom:30px !important;
  margin-top:0px !important;
  margin-bottom: -31px !important;
  }
  .drag-drop.dropped-out .descrizione {
  font-size: 12px !important;
  }
  #the-canvas{
  height:1122.519685px !important;
  width: 793.7007874px;
  }
  canvas{
  height:1122.519685px !important; 
  width: 793.7007874px !important;
  border: 1px solid #000;
  }
</style>